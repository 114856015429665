.checklist-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  }

.list-topbody-left {
  display: flex;
  flex-direction: column;
  width: 42%;
  margin-left: 8em;
  margin-top: 4em;
  margin-bottom: 4em;
}

.list-topbody-left >h3 {
  font-size: 40px;
}

.list-topbody-left > p {
 font-size: 25px;
 margin-top: 0em;
 color: grey;
}

.list-topbody-left > button {
  width: 230px;
  height: 55px;
  border-radius: 8px;
  background-color: #5dcf6c;
  border-color: #5dcf6c;
}

.animation-picture {
display: flex;
flex-direction: row;
margin: 6em;
}

.btn-txt {
  color: white;
  font-size: 20px;
}

.checklist {
  background: linear-gradient(to bottom, rgba(245, 246, 247, 1) 0%, rgba(245, 246, 247, 0.5) 50%, rgba(245, 246, 247, 0) 100%);
  border-radius: 15px;
  margin-bottom: 2em;
  padding-bottom: 3em;
}

.checklist > p {
  margin-left: 3em;
  margin-top: 2em;
}

.checklist-item {
  font-weight: 900;
  font-size: 20px;

}
.checklist-item-1 {
  font-weight: 900;
  font-size: 20px;
  color: #949393;

}
.checklist-item-2 {
  font-weight: 900;
  font-size: 20px;
  color:#d3d3d3;
}

@media screen and (max-width: 767px) {
  .checklist-container {
    display: flex;
    flex-direction: column;
  }
  .list-topbody-left {
    margin-top: 24em;
    width: 75%;
    margin-left: 0em;
    margin-bottom: -5em;
  }
  .list-topbody-left >h3 {
    align-self: center;
  }
  
  .list-topbody-left > button {
    align-self: center;
  }
  .animation-picture {
    margin-top: 4em;
    margin-bottom: 1em;
  }
}