.motto-cont {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.motto-word-container {
  padding-top: 7em;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.moto-title {
  text-align: center; 
  margin-bottom: 1em; 
}

.moto-title >h1 {
  font-size: 50px;
  position: relative;
  display: inline-block;
  color: black;
}



.moto-title >h1:after {
  text-decoration: underline;
  content: "";
  position: absolute;
  border-radius: 5px;
  width: 100%;
  height: 8px;
  bottom: 0;
  left: 0;
  background-color: #ffd200;
}

.motto-h1 >span{
  position: relative;
  display: inline-block;
  color: black;
}
.motto-h1 >span:after {
  text-decoration: underline;
  content: "";
  position: absolute;
  border-radius: 5px;
  width: 100%;
  height: 8px;
  bottom: 0;
  left: 0;
  background-color: #ffd200;
  z-index: -1;
}
.section-1-title {
  text-align: left; 
  align-self: flex-start;
  align-content: start;
  margin-left: -10em; 
  margin-bottom: 1em; 
}

.m-body-div {
  display: flex;
  flex-direction: row;
  margin-top:-9em ;
}
.topbody-left-m {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left:10em;
  margin-right: -1em;
  margin-top: 10em;
}

.topbody-left-m >h1 {
  font-size: 40px;
}

.topbody-left-m > p {
 font-size: 25px;
 color: grey;
}
.motto-right {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 8em;
  margin-top: 12em;
  width: 60%;
  }
.motto-right >p {
  margin-left: -1em;
}
  .sec-bod-div {
    width:80%;
    margin-bottom: 5em;
    margin-top: 2em;
  }

  .sec-bod-div >h2 {
    
  }

  .motto-picture {
    margin-top: -2em;
  }


  .motto-picture > img {
    width: 32em;
    height:26em;
   margin-left: 6em;
   margin-top: 4em;
  }

  .m-grid {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
  }

  .grid-left {
    width: 20em;
  }

  .grid-right {
    margin-left: 0em ;
    width: 20em;
  }

  .car-filled {
    color: #ffd200;
    font-size:xx-large ;
    vertical-align: middle;
  }
.grid-words {
  font-size: 16px;
}

.faq-container {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 2em;
}

.faq-item {
  
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.question {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
}

.question.active {
  background-color: #ffd200;
}

.answer {
  padding: 10px;
}





@media screen and (max-width: 1060px){
 .m-body-div {
  display: flex;
  flex-direction: column;
  align-items: center;
 }

 .moto-title {
  margin-top: -7em;
 }

 .motto-h1 {
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 3em;
  display: none; /* Add this line to make the <h1> inline */

}

.motto-h1 span {
  white-space: nowrap;
  display: inline; /* Add this line to make the <span> inline */
}
.grid-left{
  margin-left: -4em;
  margin-top: 7em;
}
.grid-right{
  margin-left: -4em;
}
.topbody-left-m{
  margin-left: -7em;
  margin-top: 0em;
}

.motto-right{
  margin: 0;
}

.motto-picture > img {
  margin-left: -5em;
  width: 25em;
  height: 20em;
}

.moto-title >h1:after {
  text-decoration: underline;
  content: "";
  position: absolute;
  border-radius: 5px;
  width: 65%;
  margin-left: 1.5em;
  height: 8px;
  bottom: 0;
  left: 0;
  background-color: #ffd200;
}
}