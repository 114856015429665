.why-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(245,246,247);
}

.why-left {
  display: flex;
  flex-direction: column;
  width: 42%;
  margin: 9em;
  margin-top: 5em;
}
.animation-picture-1 {
  display: flex;
  flex-direction: row;
  margin: 3em;
  }

.why-card-words {
  padding-top: 2em;
  padding-left: 2em;
  height: 13em;
  margin-bottom: 4em;
  display: flex;
  flex-direction: row;
}
.card-why {
  background-color: white;
  border-radius: 20px;
  width: 100%;
  margin: 2em;
}

.why-arrow {
  margin-top: 5em;
  margin-right: 1em;
  
}

@media screen and (max-width: 767px) {
  .why-container {
    display: flex;
    flex-direction: column;
  }
  .why-left {
    width: 75%;
    margin-bottom: 0em ;
  }
} 