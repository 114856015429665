button {
  cursor: pointer;
}

button:hover {
  scale: 1.05;
}

@media screen and (max-width: 767px) {
  .app{
    width: 100%;
  }
}