/* .cards-container {
  background-color: rgba(245,246,247);
} */
.card-container {
  display: flex;
  justify-content: center;
  /* background-color: rgba(245,246,247); */

}

.card {
  background-color: white;
  border-radius: 20px;
  width: 90%; /* Adjust the width as needed */
  margin: 20px; /* Add margins as needed */
}

.card-words {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 3em;
  width: 70%;
}

.card-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-title > h4 {
  color:#5dcf6c;
  margin-top: 5em;
  margin-bottom: 0px;
}

.card-title > p {
justify-content: center;
width: 70%;
} 

.card-container {
display: flex;
flex-direction: row;
margin: 10em;
margin-top: 2em;
}

/* .card {
  background-color: white;
  border-radius: 20px;
  width: 50%;
  margin: 2em;
} */


.btn-txt {
  color: white;
  font-size: 17px;
}
.card-words > button {
  width: 150px;
  height: 50px;
  border-radius: 8px;
  margin-top: 1.5em;
  background-color: #5dcf6c;
  border-color: #5dcf6c;
}

.card-title > p {
  margin-top: 0em;
  text-align: center;
  font-size: 20px;
}
.card-symbol {
  filter: drop-shadow(15px 5px 10px rgba(0, 0, 0, 0.2));
  text-shadow: 15px 15px 20px #9E9E9E;
}

@media screen and (max-width: 430px) {
 

  .card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   
  }
  .card {
    width: auto;
    /* margin: 0em;
    padding: 2em; */
    /* margin-bottom: 2em; */
    
  }
  .card-words {
    margin: 0em;
    margin-left: 1em;
   text-align: center;
   padding: 2em;
    
  }

  .card-symbol-2 {
    display:none;
  }


}


@media screen and (max-width: 767px) {
  .cards-container {
    width: 100%;
  }

  .card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   
  }
  .card {
    width: auto;
    /* width: 100%; */
  }

  .card-words {
    margin: 0em;
    width: 19em;
    margin-left: -1em;
    text-align: center;
    padding: 2em;
    
  }

}

