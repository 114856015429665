.km-column {
  display: flex;
  flex-direction: column;
}



.error-message {
  /* border-bottom: 2px solid red; */
  color: red;
  align-self: flex-start;
  margin-left: 4.7em;
  margin-top: -1em;
  margin-bottom: 2em;
}

#fuel-type-select {
  margin-right: 9em;
  margin-left: -1em;
}


/* select {
  margin-right: 2em;
  align-self: flex-start;
  margin-left: 7em;
  width: 20em;
  color: grey;
  border-color: grey;
  border-radius: 0px;
  margin-bottom: 2em;
} */

select {
    margin-right: 2em;
    align-self: flex-start;
    margin-left: 6em;
    border: none;
    width: 15em;
    height: 2em;
    color: grey;
    /* border: grey; */
    border-color: grey;
    border-radius: 0px;
    margin-bottom: 2em;
}

.claim-form > button {
  margin-top: 2em;
}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}


.close-btn {
  display: flex;
  margin-left: 33.5em; /* Adjust as needed */
  cursor: pointer;
  align-self: flex-end;
}

.close-btn :hover {
  transform: scale(1.10);
}

.pop-up-word {
  text-align: center;
}





@media screen and (max-width: 1060px) {
  .under-nav{
    display: flex;
   flex-direction: column-reverse;
   align-items: center;
 }
  .quote-right {
    display: none;
  }


  .vehicle-type-cont select {
    display: block; /* Stack the selects on top of each other */
    width: 100%; /* Set the selects to full width on small screens */
    margin-bottom: 10px; /* Add spacing between the stacked selects */
}
}
