.claim-container {

}

.claim-nav {

}

.under-nav {
  background-color: #c7eccb;
  padding-top: 6em;
  display: flex; 
  flex-direction: row;
  justify-content: space-around;

}

.c-t {
  margin-top: 3em;
}

.claim-contact-title {
  margin-top: 6em;
}

.claim-form-container {
  background-color: white;
  width: 60%;
  border-radius: 25px;
  margin-right: -2em;
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;

}
.claim-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.claim-label {
  align-self: flex-start;
  margin-left: 4em;
  margin-bottom: 2em;
}

.vehicle-type-cont {
  display: flex;
  flex-direction: row;
}

.vehicle-type-select {
  margin-right: 2em;
  align-items: flex-start;
  width: 20em;
  color: rgb(47, 6, 6);
  border-radius: 5px;
  margin-bottom: 2em;
}

.vehicle-make-select {
  margin-right: 24em;
  align-items: flex-start;
  width: 20em;
  color: grey;
  border-radius: 5px;
  margin-bottom: 2em;
}

.vehicle-type-select > option {
  color: grey;
}

.claim-form-container > h2 {
  margin-left: 2em;
  margin-bottom: 2em;
  padding-top: 2em;
}

.claim-form > input {
  border: none;
  border-bottom: 1px solid #000;
  margin-bottom: 3em;
  padding-bottom: 10px;
  outline: none; 
  width: 80%;
}

.claim-form > button {
  display: flex; 
  flex-direction: column;
  width: 80%;
  margin-bottom: 3em; 
  background-color: #5dcf6c;
  border-color: #d2eeff; 
  border-radius: 5px;
}

.claim-btn-word {
  align-self: center;
  padding: 0;
  font-weight: 900;
  color: white;
}

@media screen and (max-width: 1060px){
  .under-nav{
     display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .contact-left {
    margin-top: -4em;
    margin-bottom: 2em;
  }

  .claim-form-container {
    width: 80%;
    margin-right: 0em;
  }
  
  .claim-form-container > h2 {
    width: 80%;
    margin-left: 1em;
    text-align: center;
  }
}