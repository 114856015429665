.bottom-container {
  background-color: #c7eccb;
  margin-top: -10em;
  height: 40em;
}

.bottom-words > h1 {
  color: #5dcf6c;
  padding-left: 3em;
  margin-top: 0em;
  width: 30%;
  font-size: 45px;
  font-weight: bolder;
}

.bottom-words > h3 {
  color: orange;
  font-weight: 700;
  padding-top: 9em;
  padding-left: 6.7em;
}

.bottom-text-container {
  background-color: white;
  margin-bottom: 14em;
  margin-top: -10em;
  margin-left: 30em;
  border-radius: 25px;
  box-shadow: 5px 5px 10px #9E9E9E;
  margin-left: 36em;
  margin-top: -5em;
}

.bottom-words {
  margin: 5em;
}

.bottom-text-container  {
  padding: 2.5em;
}

.text-checklist {
  display: flex;
  flex-direction: column;
}



.checklist-section {
  display: flex;
  flex-direction: row;
}

.btn-txt {
  color: white;
  font-size: 17px;
}
.text-checklist > button {
  width: 150px;
  height: 50px;
  border-radius: 8px;
  margin-top: 1.5em;
  background-color: #5dcf6c;
  border-color: #5dcf6c;
}


@media screen and (max-width: 480px) {
  .bottom-text-container {
    background-color: white;
    width: 90%;
    /* position: relative; */
    border-radius: 25px;
    box-shadow: 5px 5px 10px #9E9E9E;
  }


  
  .bottom-words > h1 {
    display: none;
  }
  .bottom-words > h3 {
    display: none;
  }
  
  
}
@media screen and (max-width: 580px) {
  .checklist-section {
    display: flex;
    flex-direction: column;
  }
  
  .bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87vh; 
  }
  
}
@media screen and (max-width: 790px) {
  .bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 61vh; 
  }


}


@media screen and (max-width: 990px) {
  .bottom-words > h1 {
    width: 80%;
    align-self: center;
    padding-left: 0%;
    margin-top: 0cap;
    margin-left: 1em;
    text-align: center;
    margin-top: 0%;
  }

  .floating-container {
    margin-top: 0;
    width: 36%;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 55vh;  */
    background-color:white
  }
  
  .bottom-words {
    text-align: center; 
  }
  
  .bottom-text-container {
    display: flex;
    flex-direction: column;
    text-align: left; 
    margin-top: 34em;
    margin-left: 28em;

  }
  
  .bottom-words > h1 {
    display: none;
  }
  .bottom-words > h3 {
    display: none;
  }

  .tenant-ins-words {
    margin-bottom: 5em;
  }
}

