.Topbody-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  }

.topbody-left {
  display: flex;
  flex-direction: column;
  width: 42%;
  margin: 5em;
  margin-left: 9em;
  margin-top: 5em;
}

.topbody-left >h1 {
  font-size: 50px;
}

.topbody-left > p {
 font-size: 25px;
 color: grey;
}

.topbody-left > button {
  width: 230px;
  height: 55px;
  border-radius: 8px;
  background-color: #5dcf6c;
  border-color: #5dcf6c;
}
.topbody-left > button:hover {
  transform: scaleX(1.05);
  cursor: pointer;
}

.animation-picture {
display: flex;
flex-direction: row;
}

.btn-txt {
  color: white;
  font-size: 20px;
  font-weight: 800;
}

.animation-picture >img {
  margin-top: 5em;
  width:30em;
  height: 30em;
}

@media screen and (max-width: 480px) {
  .Topbody-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .topbody-left {
    width: 80%; 
    margin-left: 7em;
    margin-bottom: -8em;
  }
  .topbody-left >h1 {
    align-self: center;
    margin-bottom: 0em;
    margin-top: 2em;
  }

  .topbody-left >button {
    align-self: center;
  }

  .animation-picture >img {
    width: 20em;
    height: 21em;
  }
}


@media screen and (max-width: 767px) {
  .Topbody-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .topbody-left {
    width: 80%; 
    margin-bottom: -8em;
  }
  .topbody-left >h1 {
    align-self: center;
    margin-bottom: 0em;
    margin-top: 0em;
  }

  .topbody-left >button {
    align-self: center;
  }

  .animation-picture {
  }
}
