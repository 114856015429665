.nav-container {
  display: flex; 
  justify-content: space-between;
  width: 100%; 
  padding-top: 1em;
  height: 5em;
  align-items: center;
  position: fixed;
  background-color: white;
  z-index: 999;
}
.logo img {
  width: 160px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-left: 6em;
  cursor: pointer;
}



.logo:hover {
  scale: 1.05;
}

.nav-list {
  display: flex;
  list-style-type: none;
  font-weight: 800;  margin-right: 2em;
  color: black;
  
  
}
.nav-list > li {
  margin: 17px; 
  font-weight: 550;
  font-size: 16px;
  color: black;
}

.nav-list > li:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.arrow {
  display: inline-flex;
  align-items: center; 
}

.arrow-down {
  margin-bottom: 2px;
  margin-left: 5px;
}


.buy-ins {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  background-color: #5dcf6c;
  color: white;
  border-radius: 5px;
  margin-bottom: 25px !important;
  margin-top: 8px !important;
  
}

/*   background-color: #1DA1F2; */

.buy-word {
  color: white;
  margin: 10px  !important;
}



.nav-list {
  padding: 0;
  position: relative; /* Ensure the parent is positioned relatively */
}



.arrow-down {
  transition: transform 0.3s ease;
}

.open {
  transform: rotate(180deg);
}

.dropdown-content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 80%;
  left: -5%;
  width: 100px;
  padding: 10px;  
  flex-direction: column;
}

.dropdown-option {
  padding: 5px;

}

.dropdown-trigger {
  position: relative; /* Add this to make the parent relatively positioned */
}

@media screen and (max-width: 767px) {
  .nav-container {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    height: auto;
    position: relative; /* Ensure positioning context */
  }

  .logo {
    display: flex; /* Ensure logo container behaves as a flex container */
    align-items: center; /* Align items vertically */
    margin-top: 1em; /* Add margin-top for logo */
  }

  .logo img {
    margin-left: 1em; /* Adjust the margin for logo */
  }

  .mobile-menu-icon {
    position: absolute;
    top: 19px;
    right: 20px;
    cursor: pointer;
    margin-top: 1em; /* Adjust margin-top for burger icon to align with the logo */
    margin-right: 1em; /* Adjust margin-right for burger icon to align with the logo */
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-left: 9em;
    padding-left: 4em 0;
    padding-right: 4em 0;
    padding-bottom: 0em;
    margin-top: 0em;
    margin-bottom: 0em;
  }

  .nav-list > li {
    margin: 10px 0; /* Adjust margin for navigation items */
  }

  .buy-ins {
    margin-bottom: 10px !important; /* Adjust margin for buy insurance button */
    margin-top: 0 !important;
  }
}