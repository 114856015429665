.footer-container {
  display: flex;
  flex-direction: row;
  background-color: #c7eccb;
  justify-content: center;
  margin-left: -5em;
  padding-bottom: 2em;
  
}

.footer-left {
  width: 30%;
  margin-top: 2em;
  margin-right: -6em;
  margin-left: 5em;
}



.footer-right {
  margin-top: 4.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.footer-column {
  margin-right: 3em;
  width: 10em;
}

.footer-left > button {
  width: 180px;
  height: 40px;
  border-radius: 8px;
  margin-top: 1.5em;
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}

.footer-left > h1 {
  color: white;
}
.footer-left > p {
  color: rgb(85, 85, 85);
}

.footer-column > h2 {
  color: rgb(85, 85, 85);
  font-size:medium;
}

.footer-column > p {
  color: rgb(100, 100, 100);
}

@media screen and (max-width: 1060px) {
  .footer-right{
    display: none;
  }
  .footer-column {
    display: none;
  }
  .footer-containter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }

  .footer-left {
    text-align: center;
    width: 80%;
    padding-top: 1.2em;
    margin: 0em;
    margin-left: 5em;
    margin-bottom: -2em;
  }
  .footer-left > p {
    display: none;
  }

  .footer-left > button {
    display: none;
  }

}