.bouncing-mascot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.bouncing-mascot {
  animation: bounce 2s infinite;
  width: 200px; /* Adjust the size as needed */
  height: auto;
}

.coming-soon {
  font-size: 24px;
  margin-top: 5px;
}

.ellipsis {
  font-size: 24px;
  display: inline-block;
  animation: bounce 2s infinite alternate; /* Create a bouncing animation for the ellipsis */
}


@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust the bounce height */
  }
}
